<template>
  <div>
    <div v-if="isAdmin">
      <b-card>
        <div class="row mb-2">
          <div class="col-6">
            <h1>Notlar:</h1>
          </div>
          <div class="col-6" style="text-align: right;">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.bottom="'Butona 5 saniyede bir kez tıklanabilir.'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="saveBtnClick"
                @click="saveNote"
            >
              <feather-icon icon="SaveIcon"></feather-icon>
              Kaydet
            </b-button>
          </div>
        </div>
        <quill-editor
            v-model="content"
            :options="editorOption"
        />
      </b-card>
    </div>
    <div v-if="!isAdmin">
      <b-card>
        <quill-editor
            v-model="content"
            :options="editorOption2"
            :disabled="true"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton, BCard,
  BTable,
  BRow,
  VBTooltip,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BBadge,
  BForm,
} from 'bootstrap-vue'
import {quillEditor} from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Ripple from "vue-ripple-directive";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  components: {
    quillEditor,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BBadge,
    BForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      editorOption: {
        theme: 'snow',
      },
      editorOption2: {
        theme: 'bubble',
      },
      content: '',
      saveBtnClick: false,
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'token/getIsAdmin'
    })
  },
  created() {
    axios.get('/note/list')
        .then(response => {
          this.content = `${response.data.data.description}`
        })
        .catch()
  },
  methods: {
    saveNote() {
      this.saveBtnClick = true

      axios.put('/note/update', {
        description: this.content
      }).then(response => {
        this.$toastApiResponse(response.data)
      }).catch()

      setTimeout(() => {
        this.saveBtnClick = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
